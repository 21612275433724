import styled from "styled-components";

import logo2 from "./img/logo2.svg";
import logo3 from "./img/logo3.jpg";
import logo4 from "./img/logo4.jpg";
import logo5 from "./img/logo5.jpg";
import logo6 from "./img/logo6.jpg";
import logo7 from "./img/logo7.jpg";
import logo8 from "./img/logo8.jpg";
import logo10 from "./img/logo10.jpg";
import logo11 from "./img/logo11.jpg";
import logo12 from "./img/logo12.jpg";
import { mobileWidth } from "../../../style";

function App() {
    return (
        <>
            <center><p>Proyecto financiado por el INAEM, Ministerio de Cultura y Deporte</p></center>
            <center><p>Proyecto Subvencionado por la Consejería de Cultura, Turismo y Deporte de la Comunidad de Madrid, con cargo al Plan de Recuperación, Transformación y Resiliencia</p></center>
            <center><p>Financiado por la Unión Europea - Next Generation Europea</p></center>
            
            <GovStyled>
                <LogoStyled src={logo12} />
                <LogoStyled src={logo11} />
                <LogoStyled src={logo10} />
                <LogoStyled src={logo2} />
                <LogoStyled src={logo3} />
                <LogoStyled src={logo4} />
                <LogoStyled src={logo5} />
                <LogoStyled src={logo6} />
                <LogoStyled src={logo7} />
                <LogoStyled src={logo8} />
            </GovStyled>
        </>
    )
}

export default App;

const GovStyled = styled.div`
overflow: hidden;
    transform: translateX(+20%);
    max-width: 70%;
    @media (max-width: ${mobileWidth}) {
    transform: translateX(+4%);
    max-width: 95%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
`

const LogoStyled = styled.img`
    margin:0.5rem;
    max-height: 10rem;
    width: auto;
    height: auto;
    object-fit: contain;
    margin-inline: 0.2vw;
`;

