
let currentUser = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : { user: "", token: "", isAuth: false };

let { user, token, isAuth } = currentUser;


function decodeJWT(token) {
  try {
      const base64Url = token.split('.')[1];  // Obtener el payload
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
  } catch (error) {
      console.error("Error al decodificar el token:", error);
      return null;
  }
}

// Verifica si el token ha expirado
if (token) {
  try {
    const decodedToken = decodeJWT(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      // El token ha expirado
      isAuth = false;
      token = null;
      user = "";
      currentUser = { user, token, isAuth };
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
      console.log("token expirado")
    }
  } catch (error) {
    console.error("Error al decodificar el token:", error);
    isAuth = false;
  }
}

export const initialState = {
  user: user,
  token: token,
  loading: false,
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
      };
    case "LOGOUT":
      return {
        user: "",
        loading: false,
      };
    default:
      console.warn(`Unhandled action type: ${action.type}`);
      return initialState;
  }
};
