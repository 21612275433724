import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import SeleccionarFechas from './SeleccionarFechas';
import Pago from './Pago/Pago'
import GraciasPorSuCompra from './GraciasPorSuCompra';
import { useDispatch, useSelector } from 'react-redux';
import { fetchObra, selector } from '../../redux/slices/ObrasSlice'
import { useAuthState } from '../../context'

export function ComprarEntradas() {

    const queryString = window.location.search;

    const { id, date, mode } = useParams();
    const { obra } = useSelector(selector);

    const { user } = useAuthState();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [detallesCompra, setDetallesCompra] = useState({
        fechaObra: date,
        idObra: id,
        email: user?.email,
        name: user?.name,
        phone: user?.phone,
        titulo: obra?.titulo,
        cantidad: 1,
    });

    useEffect(() => {
        if (!obra?.titulo) dispatch(fetchObra(id));
    }, [id])

    useEffect(() => {
        setDetallesCompra({
            ...detallesCompra,
            titulo: obra.titulo
        })
    }, [obra?.titulo])

    useEffect(() => {
        localStorage.setItem("detallesCompra", JSON.stringify(detallesCompra));
      }, [detallesCompra]);

    useEffect(() => {
        if (queryString.includes('redirect_status=succeeded')) {
            navigate(`/sala/programacion/${id}/${date}/comprar-entradas/gracias${queryString}`);
        }
    }, [queryString]);

    function CustomComponent() {
        switch (mode) {
            case "gracias":
                return <GraciasPorSuCompra queryString={queryString} detallesCompra={detallesCompra} />
            case "pago":
                return <Pago detallesCompra={detallesCompra} />
            case "ok":
                return <SeleccionarFechas
                    setDetallesCompra={setDetallesCompra}
                    detallesCompra={detallesCompra} />
            default:
                return <p>error</p>
        }
    }

    return (
        <ComprarEntradasStyled>
            <CustomComponent />
        </ComprarEntradasStyled>
    )

}



const ComprarEntradasStyled = styled.div`
width: 90%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
font-family: Neuropol;
white-space: nowrap;
margin-top: 3vw;
color: #f46d2b;

p{
    font-family: 'Roboto', sans-serif;
}

label{
    font-family: 'Roboto', sans-serif;
    font-size: 2vw;
}

select{
    font-size: 1.5vw;
    color: #f46d2b;
    border: none;
    background: none;
    :hover{
        cursor:pointer;
    }
}

h3, p{
    color: black;
}
label{
    color: black;
}
pre{
    color: red;
    font-size: 3vw;
}
a, i{
    color: black;
    padding: 1vw;
    :hover{
        color:white;
        background-color: #f46d2b;
        cursor: pointer;
    }
}
}
button{
    margin: 2vw;
    padding: 2vw;
    font-family: Neuropol;
    font-size: 1vw;
    color: black;
    background: none;
    border: 0.2vw solid #f46d2b;
    border-radius: 2vw;
    :hover{
        background: #f46d2b;
        border: 0.2vw solid black;
        color: white;
        cursor: pointer;
    }
}
`