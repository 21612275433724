import { useNavigate } from 'react-router-dom';
import { PageStyled } from '../../style';
import { HrStyled } from '../../components/Nav';

function App() {

    const navigate = useNavigate();
    function click(e) {
        e.preventDefault();
        navigate("/escuela/cursos");
    }
    return (
        <PageStyled>
            <p className='head-1'>Escuela de Teatro</p>
            <HrStyled />
            <img src="https://lausinabucket2.s3.eu-west-1.amazonaws.com/Para+Escuela.jpg"></img>
            <HrStyled />
            <div className='text'>
                <p>La Usina está compuesta por un grupo de profesionales del ámbito teatral, actores, directores y dramaturgos, dedicados a la creación, producción y desarrollo de proyectos teatrales, a la investigación en el campo de la técnica y la estética teatral, y a la formación de grupos generadores de hechos teatrales.</p>
            </div>
            <HrStyled />
            <div className="video">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/5ddBXTJMUvg?si=gUgcg9iwxf7R5N_y" title="La Usina Escuela de Teatro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <HrStyled />
            <div className='text'>
                <p className="orange-t">¿Por qué estudiar en La Usina?</p>
                <p><span className="orange">Porque es una Escuela y es a la vez una Sala Teatral</span>, lo que te permite estar cerca del hecho teatral, de la creación y producción de espectáculos, porque la práctica escénica es habitual y cercana, porque tus clases son en la sala teatral, y porque los alumnos de La Usina terminan desarrollando sus proyectos en la sala o participando de las producciones de la misma.</p>
                <p><span className="orange">Porque es un Espacio de Creación e Investigación</span>, con claridad técnica en sus objetivos, que estimula el desarrollo de tu propia mirada, algo fundamental para que tu formación se desarrolle con libertad y autonomía, para que estés bien preparado y puedas desenvolverte en cualquier medio actoral, y a la vez, tengas la capacidad de crear y desarrollar proyectos teatrales propios.</p>
                <p><span className="orange">Porque todos sus Profesores son Profesionales en Activo</span>, dedicados a la investigación pedagógica, que desarrollan sus propios proyectos de creación, en interpretación, dramaturgia, o dirección, y que a la vez, comprenden el proyecto de formación de La Usina, como algo vivo, en constante replanteo y evolución, asociando la formación, a la creación y a la investigación.</p>
            </div>
            <button onClick={click}>Ver Cursos</button>
        </PageStyled>
    );
}

export default App;