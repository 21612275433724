import styled from "styled-components";
import { mobileWidth } from "../../../style";

function App() {
    return ( 
        <RRSSContainer>
            <a href="https://es-es.facebook.com/sala.lausina/" target="_blank"><i className="fab fa-facebook"></i></a>
            <a href="https://www.instagram.com/sala.la.usina" target="_blank"><i className="fab fa-instagram"></i></a>
            <a href="https://www.youtube.com/c/SalaLaUsina" target="_blank"><i className="fab fa-youtube"></i></a>
            <a href="https://twitter.com/Sala_La_Usina" target="_blank"><i className="fab fa-twitter"></i></a>
            <a href="tel:34670580570" target="_blank"><i className="fas fa-phone"></i></a>
            <a href="https://wa.me/34670580570" target="_blank"><i className="fab fa-whatsapp"></i></a>
            <a href="mailto:info@lausina" target="_blank"><i className="fas fa-envelope"></i></a>
        </RRSSContainer> );
}

export default App;

const RRSSContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: none;
    margin-top: 100px;
    background-color: black;
    max-width: 100%;
    overflow: hidden;
    
    a{
        @media (max-width: ${mobileWidth}) {
            font-size: 1.2rem;
            margin: 1rem;
        }
        color: white;
        font-size: 3rem;
        margin: 2rem;
        :hover{
            cursor: pointer;
            color: #f46d2b;
        }
    }
`