import { Link } from "react-router-dom";
import styled from "styled-components";

const LegalFooter = () => {
    return (
        <LegalFooterStyled>
            <a href="/transparencia">Transparencia</a>
            <p>© 2024 Sala de Teatro La Usina. Todos los derechos reservados.</p>
            <a href="https://maps.app.goo.gl/ncatDP69a3JYHALr5" target="_blank">Calle de Palos de la Frontera, 4, 28012 Madrid</a>
        </LegalFooterStyled>
    )
}

export default LegalFooter;

const LegalFooterStyled = styled.div`
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 1.1rem;
    a { 
        color: #f46d2b;
    }
`