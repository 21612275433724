import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import styled from "styled-components";


import CheckoutForm from "./CheckoutForm";
import { postAPI } from "../../../api";
import { useNavigate, useNavigation } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_LA_USINA_STRIPE_TEST);

const apiUrl = process.env.LA_USINA_API_URL;
const queryString = window.location.search;

export default function Pago() {

  const [detallesCompra] = useState(JSON.parse(localStorage.getItem("detallesCompra")));
  const [clientSecret, setClientSecret] = useState("");

  const [error, setError] = useState(false)
  const navigate = useNavigate();

  async function fetchMyAPI() {
    let response = await postAPI('create-payment-intent', JSON.stringify(detallesCompra))
    setClientSecret(response.clientSecret)
    if (JSON.stringify(response).includes('code 500')) {
      setError(true)
    }
  }

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (!window.location.search.includes('redirect_status=succeeded')) {
      fetchMyAPI();
    }
    return () => { }
  }, []);

  const dateValue = Number(detallesCompra?.fechaObra);
  const date = new Date(dateValue).toLocaleDateString("es-ES", {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  })
  const options = {
    clientSecret,
  };
  return (
    <SrtipeStyled className="App">
      <h3>Comprar entradas para {detallesCompra?.titulo}</h3>
      <p>Cantidad: {detallesCompra?.cantidad}</p>
      <p>Enviar a: {detallesCompra?.email}</p>
      <p>Fecha y hora: {date}</p>
      <p>Notas: {detallesCompra.notasEspeciales}</p>
      {error && 'Error, compruebe cantidad disponible'}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />

        </Elements>
      )}
      <button className="cancelar" onClick={(e) => { e.preventDefault(); navigate(window.location.pathname.split('/').slice(0, -1).join('/') + '/ok'); }}>Cancelar</button>
    </SrtipeStyled>
  );
}

const SrtipeStyled = styled.div`
  #root {
    display: flex;
    align-items: center;
  }

  h3 {
    color:
  }

  p{
    font-family: Roboto;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
  }
  
  form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  
  
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Roboto, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }

  .cancelar{
    background: black;
    color: white;
  }
  
  button:hover {
    filter: contrast(115%);
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
    }
  }
`