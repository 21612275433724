
import { createGlobalStyle } from 'styled-components'
import Nav from '../Nav';
import Footer from '../Footer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function Layout({ children }) {


  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <GlobalStyle />
      <Nav />
      {children}
      <Footer />
    </div>
  );
}

export default Layout;


export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: 'Neuropol';
    src: url("/fonts/neuropol.ttf") format('truetype');
  }
  
  html, #root, #app {
    min-height: 100% !important;
    height: 100%;
  }
  a{
    text-decoration: none;
  }
  p h3{
    overflow-x:hidden

  }

  .table-container {
    max-width: 100px;
    overflow-x: auto;  /* Desbordamiento horizontal se manejará con una barra de desplazamiento si es necesario */
    overflow-y: hidden;  /* El desbordamiento vertical estará oculto */
    img{
      width:10px;
    }
  }

  body {
    min-width: 100%;
    min-height: 100% !important;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x:hidden
    font-family: 'Neuropol';
  }


`