import { useEffect } from 'react';
import Layout from '../../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurso, selector } from '../../../redux/slices/CursosSlice';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from "draft-js";
import { convertirSinopsis } from '../../../components/Helpers/convertirSinopsis';
import { mobileWidth } from '../../../style';



function Curso() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { curso, loading } = useSelector(selector);

    useEffect(() => {
        dispatch(fetchCurso(id));
    }, [])

    const handleClickLeft = (e) => {
        navigate(-1);
    }
    if (loading) return <h3>Cargando...</h3>
    else return (
        <CursoStyled>
            <div className="head">
                <i className="fas fa-chevron-left" onClick={handleClickLeft}></i>
                <p className='titulo'>{curso.nombre}</p>
            </div>
                <img src={curso.cartel}></img>
            {curso?.descripcion && <div
                className='sinopsis'
                id='sinopsis'
                dangerouslySetInnerHTML={{
                    __html: convertirSinopsis(curso?.descripcion)
                }}></div>}
            <div className='text'>
                <p className='title'>{curso.profesores?.lenght > 1 ? "Profesores" : "Profesor"}</p>
                <p>{curso.profesores?.map(autor => {
                    return autor
                })}</p>
            </div>
        </CursoStyled>

    );
}

export default Curso;

const CursoStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-inline: 10vw;
    overflow: hidden;

    @media (max-width: ${mobileWidth}) {
         margin-inline: 0;

    }
    img {
        min-width: 25vw;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        @media (max-width: 1118ps) {
            min-width: 100%;
        }
    }
    .titulo{
        font-family: Neuropol;
        font-size: 4rem;
        color: #f46d2b;
        @media (max-width: ${mobileWidth}) {

            font-size: 2rem;
        }
    }
    .sinopsis {
        @media (max-width: ${mobileWidth}) {
            font-size: 2rem;
            max-width: 95%;
            margin-top: 5rem;
        }
        font-size: 2rem;
        max-width: 85%;
        margin-top: 5rem;
    }
    p{
        margin: 0.7vw;
    }
    .text {
        padding: 0px;
        flex-direction: column;
        font-size: 2rem;
        .title{
            color: #f46d2b;
            font-family: Neuropol;
        }
    }
    .head{
        margin: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: row;
        i{
            position: absolute;
            left: 0;
            font-size: 3vw;
            margin-left: 1vw;
            :hover{
                cursor: pointer;
                color: #f46d2b;
            }
        }
    }
`