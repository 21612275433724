import Layout from '../../components/Layout';
import { useNavigate } from 'react-router-dom';
import { PageStyled } from '../../style';
import { HrStyled } from '../../components/Nav';

function App() {

    const navigate = useNavigate();
    function click(e) {
        e.preventDefault();
        navigate("/sala/programacion");
    }
    return (
        <PageStyled>

            <p className='head-1'>Sala de Teatro</p>
            <HrStyled />
            <img src="https://lausinabucket2.s3.eu-west-1.amazonaws.com/Para+Sala.jpg"></img>
            <HrStyled />
            <div className='text'>
                <p><span className="orange">Un espacio para la Creación</span>, con una programación estable, que se renueva constantemente, a partir de una selección cuidada de proyectos teatrales innovadores y de calidad. Una sala que combina producciones propias, con espectáculos de compañías emergentes y consolidadas, en busca de ofrecer el mejor teatro.</p>
            </div>
            <HrStyled />
            <div className='video'>
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/C8zKTD1AfGc?si=Dxd6tvEyKPTU1TFv" title="La Usina Sala de Teatro" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <HrStyled />
            <div className='text'>
                <p><span className="orange">Un referente en la programación teatral Madrileña</span>, que crece en la elección del público, e intenta albergar con calidez y cuidado, las propuestas más renovadoras de nuestro panorama teatral.</p>
            </div>
            <button onClick={click}>Ver Programación</button>
        </PageStyled>
    );
}

export default App;