import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { signUpUser } from '../../context/actions'
import { useNavigate, useParams } from 'react-router-dom'
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from 'google-libphonenumber';
import "react-international-phone/style.css";
import "./style.css";
import styled from 'styled-components'
import { StyledForm } from '../../style'

function SignUp({ titulo }) {

  const navigate = useNavigate();
  // const userDetails = useAuthState();
  const [phone, setPhone] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const { id, date } = useParams();

  const onSubmit = async values => {
    values.idObra = id
    values.dateObra = date
    values.phone = phone;
    const res = await signUpUser(null, values);
    let mensaje = document.getElementById("mensaje");
    if (res)
      mensaje.innerHTML = res;
    if (res.includes("Usuario registrado")) {
      mensaje.style.color = "green";
      setTimeout(() => {
        navigate('/sala/programacion/' + id + '/' + date + '/comprar-entradas/login')
      }, 5000);
    } else {
      mensaje.style.color = "red";
    }
  }

  const phoneUtil = PhoneNumberUtil.getInstance();

  useEffect(() => {
    if (phone.length > 6) {
      if (isPhoneValid(phone)) setPhoneErrorMessage("")
      else setPhoneErrorMessage("Introduce un teléfono válido")
    }
  }, [phone])

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return String(email)
      .toLowerCase()
      .match(
        re
      );
  };

  const minLength = (min) => (value) =>
    value && value.length < min ? `Debe tener al menos ${min} caracteres` : undefined;


  const RenderField = ({ name, label, type = "text", placeholder }) => (
    <Field name={name}>
      {({ input, meta }) => (
        <div>
          <label>{label}</label>
          <input {...input} type={type} placeholder={placeholder} />
          {meta.error && meta.touched && <span>{meta.error}</span>}
        </div>
      )}
    </Field>
  );

  return (
    <StyledForm>
      <h1>{titulo || "Registrarse"}</h1>
      <Form
        onSubmit={onSubmit}
        validate={values => {
          const errors = {};

          // Validaciones
          if (!values.name) errors.name = 'Requerido';
          if (!values.email) {
            errors.email = 'Requerido';
          } else if (!validateEmail(values.email)) {
            errors.email = 'Email no válido';
          }
          if (!values.password) {
            errors.password = 'Requerido';
          } else if (minLength(8)(values.password)) {
            errors.password = 'Debe tener al menos 8 caracteres';
          }
          if (!values.confirm) {
            errors.confirm = 'Requerido';
          } else if (values.confirm !== values.password) {
            errors.confirm = 'Las contraseñas no coinciden';
          }

          return errors;
        }}
        render={({ handleSubmit, submitting, invalid }) => (
          <form onSubmit={handleSubmit}>
            <RenderField name="name" label="Nombre" placeholder="Nombre" />
            <RenderField name="email" label="Email" placeholder="Email" />
            <RenderField name="password" label="Contraseña" type="password" placeholder="Password" />
            <RenderField name="confirm" label="Repetir" type="password" placeholder="Confirm" />
            <label>Teléfono</label>
            <PhoneInput
              defaultCountry="es"
              value={phone}
              onChange={(p) => setPhone(p)} />
            <span>{phoneErrorMessage}</span>
            <div className="buttons-login">
              <button type="submit" disabled={submitting || invalid}>
                Continuar
              </button>
              <button className='button-reversed' onClick={(e) => { e.preventDefault(); navigate('/sala/programacion/' + id + '/' + date + '/comprar-entradas/login') }}>Iniciar sesión</button>
            </div>
          </form>
        )}
      />
      <p id="mensaje"></p>

    </StyledForm>
  )
}

const PhoneStyled = styled.div`

`

export default SignUp;