import styled from "styled-components"

export const mobileWidth = '1118px';

export const PageStyled = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
flex-direction: column;
overflow: hidden;
img {
    width: 100%;
}
.sinopsis {
    font-size: 2rem;
}
p{
}
a {
    color: unset;
}
.text {
    padding: 0px;
    flex-direction: column;
    font-size: 2rem;
    font-family: 'Roboto', sans-serif; 

    margin-top: 8rem;
    margin-bottom: 10rem;
    margin-inline: 19rem;

    @media (max-width: ${mobileWidth}) {
        font-size: 1.2rem;
        margin-top: 6rem;
        margin-bottom: 5rem;
        margin-inline: 0rem;
    }

    .orange{
        color: #f46d2b;
    }
}


.video {
    padding: 5rem;
    background-color: black;
    width: 100%;
    height: auto;
    @media (max-width: ${mobileWidth}) {
        padding: 0rem;
    }
}

@media (max-width: ${mobileWidth}) {

    iframe {
        width: 100%; /* Ancho completo dentro del contenedor para pantallas más pequeñas */
        height: auto; /* La altura se ajusta proporcionalmente */
        aspect-ratio: 16 / 9; /* Mantiene la proporción de aspecto si es necesario */
    }
}

@media (min-width: ${mobileWidth}) {
    iframe {
        max-width: 1118px; 
        height: 700px; 
    }
}

.head {
    font-family: Neuropol;
    font-size: 6rem;
    color: #f46d2b;
    margin: 0;
    margin-top: 3rem;
    @media (max-width: ${mobileWidth}) {
        margin-top: 1rem;
        font-size: 2.5rem;
    }
    
}
.head-1 {
    margin: 0;
    font-family: 'Roboto', sans-serif; 

    font-size: 6rem;
    color: black;
    @media (max-width: ${mobileWidth}) {
        font-size: 4rem;
    }
}

.orange-t{
    font-family: Neuropol;
    font-size: 4rem;
    color: #f46d2b; 
}
.titulo-p {
    max-width: 100%;
    font-family: Neuropol;
    font-size: 5rem;
    color: #f46d2b;
    @media (max-width: ${mobileWidth}) {
        font-size: 3rem;
    }

}
.head-p{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: row;
    i{
        position: absolute;
        left: 0;
        font-size: 3rem;
        margin-left: 1rem;
        :hover{
            cursor: pointer;
            color: #f46d2b;
        }
    }
}
button{
    margin: 2rem;
    padding: 3rem;
    font-family: Neuropol;
    font-size: 2rem;
    color: black;
    background: none;
    border: 0.3rem solid #f46d2b;
    border-radius: 10rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

    :hover{
        background: #f46d2b;
        border: 0.3rem solid black;
        color: white;
        cursor: pointer;
    }
    @media (max-width: ${mobileWidth}) {
        margin: 1rem;
        padding: 1.6rem;
        font-size: 1.5rem;
    }
}

`

export const CardStyled = styled.div`
  position: relative; /* Nuevo: Haz que este contenedor sea la referencia para posicionamiento absoluto */
  display: flex;
  align-items: space-around;
  justify-content: center;
  flex-direction: row;
  color: ${p => p.colorText};
  height: 18rem;
  width: 80%;
  margin-left: 9%;
  margin-top: 1%;
  text-align: center;
    :hover{
        cursor: pointer;
        opacity: 0.8;
    }

    .direccion{
        width: 100%;
        display: flex;
        align-items: space-around;
        justify-content: space-around;
        flex-direction: row;
    }
    .actores{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin: 0px;

    }

    .texto-obra{
        font-size: 1.6rem;
        margin: 0px;
        margin-left: 0.5rem;
        background-color: ${p => p.color};
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-radius: 8px; /* Bordes redondeados para el fondo */
    }
    .titulo {
        span{
            font-family: 'Roboto', sans-serif; 
            font-size: 1.5rem;
            color: ${p => p.colorText2}
            color:white;
        }
        font-family: Neuropol;
        font-size: 4rem;
        color: ${p => p.colorText2}
        background-color: rgba(0,0,0,0.5); /* Opcional: fondo oscuro para mejorar la legibilidad */
        padding: 0.5rem; /* Espacio alrededor del texto */
        border-radius: 8px; /* Bordes redondeados para el fondo */
      }

      .image-container {
        width: 30%;
        height: 100%;
      }
      .image-card {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;  /* Es importante para asegurarse de que la imagen ocupe todo el espacio definido */
        border-radius: 8px;
    }
    

@media (max-width: ${mobileWidth}) {

    width: 96%;
    height: 30rem;
    margin-left: 2.5%;

    .titulo {
        width: 92%;
        position: absolute; /* Nuevo: Posicionamiento absoluto */
        bottom: 10%;  /* Nuevo: Colocado cerca de la parte inferior */
        left: 50%; /* Centrar horizontalmente */
        transform: translateX(-50%); /* Ajuste fino para centrar horizontalmente */
        font-family: Neuropol;
        font-size: 2rem;
        margin: 0px;
        color: white;
        background-color: rgba(244, 109, 43, 0.5);

        padding: 0.5rem; /* Espacio alrededor del texto */
        border-radius: 8px; /* Bordes redondeados para el fondo */
        position: absolute;
    }
    .texto-obra{
      max-width: 100%;
      background-color: white;
      margin:0;
    }

    .image-container {
        min-width: 100%;
        height: 100%;
      }
      .image-card {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;  /* Es importante para asegurarse de que la imagen ocupe todo el espacio definido */
        border-radius: 8px;
    }
}

`;


export const CardProgramacionStyled = styled.div`
display: flex;
align-items: space-around;
justify-content: space-around;
flex-direction: row;
color: ${p => p.colorText};
height: 5rem;
width: 100%;
margin-bottom: 1%;
font-family: Neuropol;
text-align: center;
overflow: hidden;

.day{
    font-size: 2.5rem;
}
#head{
    width: 78%;
}
#head, .arrow{
    color: black;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif; 

    justify-content: center;
    font-size: 3rem;
}

.arrow{
    :hover{
        color: #f46d2b;
        cursor: pointer;
    }
}
.month{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 78%;

}
.arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9%;
}

.hour, .hour-2{
    margin-inline: 1rem;
    font-family: 'Roboto', sans-serif; 

    font-size: 1.3rem;
}

.entradas{
    :hover{
        cursor: pointer;
        text-decoration: underline;
    }
}

.obra-datos{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-size: 2rem;
    font-family: Neuropol;
    :hover{
        cursor: pointer;
        text-decoration: underline;
    }
}

.p-arrow {
    font-size: 1.5rem;
}

.texto-obra{
    width: 78%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-family: 'Roboto', sans-serif; 

    font-size: 2rem;
    flex-direction: row;
    background-color: ${p => p.color};
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

    /* Transición suave para el efecto de hover y transformación */
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    /* Efecto cuando el cursor pasa por encima */
    &:hover {
        /* Sombra más grande y difusa */
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.25);
        
        /* Ligeramente levantada */
        transform: translateY(-5px);
    }

}

.date, .date-2{
    font-size: 1rem;
    background-color: ${p => p.color};
    display: ${p => p.otherDate ? "flex" : "none"};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 9%;
}

.head-container {
    width: 98%;
    display: flex;
    align-items: space-between;
    justify-content: space-between;
    flex-direction: row;
}

@media (max-width: ${mobileWidth}) {
    flex-direction: column;
    align-items: center; /* Asegurarnos de centrar los elementos en la pantalla */
    height: auto; /* Permite que la altura se ajuste al contenido */

    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Asegura que el padding no aumente el tamaño total de los elementos */
    }

    .date {
        margin-top: 3rem;
        font-family: 'Roboto', sans-serif; 

        background-color: white;
        color: ${p => p.color};
        min-height: 6rem;
        font-size: 3rem;
        width: 100%;
        order: 1; /* Esto hará que .date venga primero */
    }
    .date-2 {
        display: none;
    }
    .obra-datos {
        font-size: 1rem;
    }
    .texto-obra {
        flex-direction: column;
        display:flex;
        text-align: center;
        order: 2; /* Esto hará que .texto-obra venga después de .date */
        width: 100%;
        font-size: 1rem;
        padding-top: 1rem; /* Añade espacio entre .texto-obra y .date */
        padding-bottom: 1rem; /* Añade espacio entre .texto-obra y .date */
    }

    .day {
        font-size: 8rem;
    }

    #head, .arrow {
        font-size: 2.5rem;
    }

    .obra-datos {
        font-size: 2rem;
    }

    .hour {
        margin-inline: 0;
        font-size: 1.2rem;
    }
    .hour-2 {
        display:none;
    }

}
`

export const ObraStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-inline: 10rem;
    overflow: hidden;
    img {
        width: 25rem;
    }
    .titulo{
        font-family: Neuropol;
        font-size: 5rem;
        color: #f46d2b;

    }
    .sinopsis {
        font-size: 2rem;
    }
    p{
        margin: 0.7rem;
        font-family: 'Roboto', sans-serif;
    }
    .text {
        padding: 0px;
        flex-direction: column;
        font-size: 2rem;
        .title{
            color: #f46d2b;
            font-family: Neuropol;
        }
    }

    .head{
        margin: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: row;
        i{
            position: absolute;
            left: 0;
            font-size: 3rem;
            margin-left: 1rem;
            :hover{
                cursor: pointer;
                color: #f46d2b;
            }
        }
    }
    button{
        margin: 2rem;
        padding: 2rem;
        font-family: Neuropol;
        font-size: 3rem;
        color: #f46d2b;
        background: none;
        border: 0.3rem solid #f46d2b;
        border-radius: 4rem;
        :hover{
            background: #f46d2b;
            color: white;
            cursor: pointer;
        }
    }

    /* Media query para tablet y móviles */
    @media (max-width: ${mobileWidth}) {
        margin-inline: 1rem;

        img {
            width: 90%;
            max-width: 400px;
        }

        .titulo {
            font-size: 2.1rem;
        }

        .sinopsis {
            font-size: 1.5rem;
        }

        .text {
            font-size: 1.5rem;
        }

        .head i {
            font-size: 2.5rem;
        }

        button{
            font-size: 2rem;
            padding: 1rem;
        }
    }
`

export const StyledForm = styled.div`
font-family: 'Roboto', sans-serif; 
max-width: 50rem;
margin: 40px auto;
padding: 20px;
border-radius: 10px;
background-color: #fff;
overflow: hidden;
h1 {
    font-family: Neuropol;
    color: #f46d2b;
}

.buttons-login {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

span {
    color: red;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #f46d2b;
    outline: none;
  }
}

button {
  padding: 10px 15px;
  background-color: #f46d2b;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-family: 'Neuropol', sans-serif; // Usar Neuropol para el botón
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: white;
    color: #f46d2b;
    border: 1px solid #f46d2b;
  }
  &:disabled {
    background-color: #ccc;  /* Color de fondo cuando está deshabilitado */
    color: #999;  /* Color del texto cuando está deshabilitado */
    cursor: not-allowed;  /* Cursor que indica que el botón no está activo */
  }
  &:disabled:hover {
    background-color: #ccc;  /* Asegúrate de que el color de fondo no cambie cuando el mouse pasa sobre un botón deshabilitado */
    color: #999;  /* Asegúrate de que el color del texto no cambie cuando el mouse pasa sobre un botón deshabilitado */
    border: none;  /* Remueve el borde en hover para botones deshabilitados */
  }
}

@media (max-width: ${mobileWidth}) {
  padding: 15px;
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    font-size: 14px;
  }
  max-width: 100%;
  margin: 10px auto;

}

.button-reversed {
    background-color: #fff;
    color: #f46d2b;
    border-color: #f46d2b;
  
    &:hover {
      background-color: #f46d2b;
      color: #fff;
    }
  }

  label {
    font-size: 1rem;
  }
  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    transition: border-color 0.3s ease;
    &:focus {
      border-color: #f46d2b;
      outline: none;
    }
  }

  @media (max-width: ${mobileWidth}) {
        #cerrar{
            bottom: 120px; /* puedes ajustar este valor según la distancia que desees desde el borde inferior */
        }
        h1, select {
            white-space: normal;
            width: 100%;  /* O cualquier valor que funcione para tu diseño */
            box-sizing: border-box; /* Esto asegura que el padding y el border se incluyan en el ancho total del elemento */
         }
    }

  #cerrar {

    position: absolute;
    bottom: -200px; /* puedes ajustar este valor según la distancia que desees desde el borde inferior */
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    text-decoration: underline;
    color: #f46d2b; /* color de enlace, puedes ajustarlo según tus preferencias */
    &:hover {
      color: #d84b0f; /* color al pasar el mouse, puedes ajustarlo según tus preferencias */
    }
  }
`;