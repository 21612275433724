import { useLocation } from "react-router-dom";
import { AuthProvider } from "./context";
import MyRoutes from "./routes";
import { useEffect } from "react";

// DISEÑO
// TODO: Cortar video home o conseguir otro mejor 2

// FUNCIONALIDAD
// TODO: Responsive 
// TODO: Añadir ATRAPALO, ENTRADIUM, etc

function App() {

  return (
    <AuthProvider>
      <MyRoutes />
    </AuthProvider>
  );
}

export default App;