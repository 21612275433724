import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAPI } from "../../api";

// esta ruta solo disponible para angie
export const fetchObrasList = createAsyncThunk(
  "Obras/fetchObrasList",
  async () => {
    return await getAPI('Obras')
      .then((data) => { return data });
  }
);

export const fetchObrasNow = createAsyncThunk(
  "Obras/fetchObrasNow",
  async () => {
    return await getAPI('obras/now')
      .then((data) => {return data});
  }
);

export const fetchObrasMes = createAsyncThunk(
  "Obras/fetchObrasMes",
  async (aniomes) => {
    return await getAPI(`obras/${aniomes}`)
      .then((data) => { return data });
  }
);

export const fetchObra = createAsyncThunk(
  "Obras/fetchObra",
  async (id, thunkAPI) => {
    return await getAPI(`obras/${id}`)
      .then((data) => { return data });
  }
);


export const ObrasSlice = createSlice({
  name: "Obras",
  initialState: {
    ObrasList: [],
    listMes: [],
    listNow: [],
    loading: true,
    obra: {},
  },
  reducers: {},
  extraReducers: {

    [fetchObra.pending]: (state) => {
      state.loading = true;
    },
    [fetchObra.fulfilled]: (state, { payload }) => {
      state.obra = payload;
      state.loading = false;
    },
    [fetchObra.rejected]: (state) => {
      state.loading = true;
    },

    [fetchObrasList.pending]: (state) => {
      state.loading = true;
    },
    [fetchObrasList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.ObrasList = payload;
    },
    [fetchObrasList.rejected]: (state) => {
      state.loading = true;
    },

    [fetchObrasMes.pending]: (state) => {
      state.loading = true;
    },
    [fetchObrasMes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listMes = payload;
    },
    [fetchObrasMes.rejected]: (state) => {
      state.loading = true;
    },

    [fetchObrasNow.pending]: (state) => {
      state.loading = true;
    },
    [fetchObrasNow.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.listNow = payload;
    },
    [fetchObrasNow.rejected]: (state) => {
      state.loading = true;
    },

  },
});

export const selector = (state) => state.obras;

export default ObrasSlice.reducer;