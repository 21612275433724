import styled from "styled-components";
import Gov from "./Gov";
import ContactoForm from "./FormContact"
import RRSS from "./RRSS";
import LegalFooter from "./Legal";


function App() {
    return (
        <>
            <RRSS />
            <ContactoForm />
            <Gov />
            <LegalFooter />
        </>
    );
}

export default App;