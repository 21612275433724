import { convertToHTML } from "draft-convert";
import { convertFromRaw } from "draft-js";

export function convertirSinopsis(raw) {
    if (raw) {
        try {
            const toHtml = convertFromRaw(JSON.parse(raw));

            const options = {
                blockToHTML: (block) => {
                    if (block.type === 'atomic') {
                        return {
                            start: '<figure>',
                            end: '</figure>'
                        };
                    }
                },
                entityToHTML: (entity, originalText) => {
                    if (entity.type === 'IMAGE') {
                        return `<img src="${entity.data.src}" alt="${entity.data.alt}" width="${entity.data.width}" height="${entity.data.height}" />`;
                    } else if (entity.type === 'LINK') {
                        return `<a href="${entity.data.url}" target="${entity.data.targetOption}">${originalText}</a>`;
                    }
                    return originalText;
                }
            };
            const html = convertToHTML(options)(toHtml);
            return html;

        } catch (e) {
            console.log(e);
        }
    }
}