import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthState } from "./context";

// Pages
import Home from "./pages/Home";

import Sala from "./pages/Sala";
import Programacion from "./pages/Sala/Programacion";
import Obra from "./pages/Sala/Programacion/Obra";
import Alquiler from "./pages/Sala/Alquiler";
import Mes from "./pages/Sala/Programacion/Mes"
import Escuela from "./pages/Escuela";
import Talleres from "./pages/Escuela/Talleres";
import Cursos from "./pages/Escuela/Cursos";
import Curso from "./pages/Escuela/Curso";
import Equipo from "./pages/Escuela/Equipo";
import Profesor from "./pages/Escuela/Equipo/Profesor";
import Layout from './components/Layout';
import {ComprarEntradas} from "./components/ComprarEntradas";
import Login from "./components/Login"
import SignUp from "./components/SignUp";
import ValidateEmail from "./components/SignUp/validateEmail"
import ChangePassword from "./components/SignUp/changePassword";
import Transparencia from "./pages/Legal";

function MyRoutes() {
  const { user, token } = useAuthState();

  function Private({ children }) {
    const route = window.location.pathname.split('/').slice(0, -1).join('/') + '/login';
    return user?.validado && token
      ? children
      : <Navigate to={route} />;
  }

  function GuestOnly({ children }) {
    const route = window.location.pathname.split('/').slice(0, -1).join('/') + '/ok';
    return !user?.validado && !token
      ? children
      : <Navigate to={route} />;
  }


  return (
    <BrowserRouter basename={process.env.LA_USINA_PUBLIC_URL}>
      <Routes>

        <Route path="login" element={<Layout><Login /></Layout>} />
        <Route path="signup" element={<Layout><SignUp /></Layout>} />
        <Route path="" element={<Home />} />

        <Route path="transparencia" element={<Layout><Transparencia /></Layout>} />


        <Route path="sala" element={<Layout><Sala /></Layout>} />
        <Route path="sala/programacion" element={<Layout><Programacion /></Layout>} />
        <Route path="sala/programacion/:id" element={<Layout><Obra /></Layout>} />
        <Route path="sala/programacion/:id/:date" element={<Layout><Obra /></Layout>} />
        <Route path="sala/programacion/:id/gracias-por-su-compra" element={<Layout><Obra /></Layout>} />
        <Route path="sala/programacion/:id/:date/verify/:uniqueString" element={<Layout><ValidateEmail /></Layout>} />
        <Route path="verify-reset-password/:passUniqueString/:email" element={<Layout><ChangePassword /></Layout>} />
        <Route path="sala/programacion/:id/:date/comprar-entradas/login" element={<Layout><GuestOnly><Login /></GuestOnly></Layout>} />
        <Route path="sala/programacion/:id/:date/comprar-entradas/signup" element={<Layout><GuestOnly><SignUp /></GuestOnly></Layout>} />
        <Route path="sala/programacion/:id/:date/comprar-entradas/:mode" element={<Layout><Private><ComprarEntradas /></Private></Layout>} />
        <Route path="sala/programacion/:id/comprar-entradas/login" element={<Layout><GuestOnly><Login /></GuestOnly></Layout>} />
        <Route path="sala/programacion/:id/comprar-entradas/signup" element={<Layout><GuestOnly><SignUp /></GuestOnly></Layout>} />
        <Route path="sala/programacion/:id/comprar-entradas/:mode" element={<Layout><Private><ComprarEntradas /></Private></Layout>} />
        <Route path="sala/programacion/mes/:anio/:mes" element={<Layout><Mes /></Layout>} />
        <Route path="sala/alquiler" element={<Layout><Alquiler /></Layout>} />

        <Route path="escuela" element={<Layout><Escuela /></Layout>} />
        <Route path="escuela/cursos" element={<Layout><Cursos /></Layout>} />
        <Route path="escuela/talleres" element={<Layout><Talleres /></Layout>} />
        <Route path="escuela/equipo" element={<Layout><Equipo /></Layout>} />
        <Route path="escuela/equipo/:id" element={<Layout><Profesor /></Layout>} />
        <Route path="escuela/:id" element={<Layout><Curso /></Layout>} />

      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;