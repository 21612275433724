import api from "./api";



export async function signupAPI({ name, email, password, idObra, dateObra, phone }) {
    return await api
        .post(`signup`,
            {
                name,
                email,
                password,
                idObra,
                dateObra,
                phone
            })
        .then(res => {
            return res.data
        })
        .catch((error) => {
            throw error.response.data.message;
        })
}
export async function loginAPI({ email, password }) {
    return await api
        .post(`login`,
            {
                email: email,
                password: password
            })
        .then(res => {
            return res.data
        })
        .catch((error) => {
            console.log(error)
            throw error;
        })
}
export async function resetPassAPI({ email }) {
    return await api
        .post(`reset-password`,
            {
                email: email
            })
        .then(res => {
            return res.data
        })
        .catch((error) => {
            // Aquí accedemos al mensaje del servidor en caso de error.
            const serverMessage = error.response && error.response.data && error.response.data.message
                ? error.response.data.message
                : error.message;

            throw serverMessage;
        })
}

export async function confirmResetPassAPI({ email, passUniqueString, newPass }) {
    return await api
        .post(`verify-reset-password`,
            {
                email,
                passUniqueString,
                newPass,
            })
        .then(res => {
            return res.data
        })
        .catch((error) => {
            console.log(error)
            throw error.message;
        })
}
export async function postAPI(url, body) {
    return await api
        .post(url, body)
        .then(res => {
            return res.data
        })
        .catch((error) => {
            throw error
        })
}

export async function postAPINoToken(url, body) {
    return await api
        .post(url, body, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            return res.data
        })
        .catch((error) => {
            throw error
        })
}

export async function getAPI(url, token) {
    return await api
        .get(`${url}`)
        .then(res => {
            return res.data

        })
        .catch((error) => {
            throw error
        })
}

export async function deleteAPI(url, id2) {
    return await api
        .delete(`${url}/${id2}`)
        .then(res => {
            return res.data

        })
        .catch((error) => {
            throw error
        })
}

export async function putAPI(url, id2, body) {
    return await api
        .put(`${url}/${id2}`, body)
        .then(res => {
            return res.data

        })
        .catch((error) => {
            throw error
        })
}