import { useNavigate, useParams } from "react-router-dom";
import { confirmResetPassAPI, resetPassAPI } from "../../api";
import { useEffect, useState } from "react";
import { useAuthState } from "../../context";
import { StyledForm } from "../../style";
import { Field, Form } from "react-final-form";

const ChangePassword = () => {

    const { passUniqueString, email } = useParams();
    const navigate = useNavigate();
    const [message, setMessage] = useState(undefined)

    const onSubmit = async (values) => {
        const clone = { ...values };
        clone.passUniqueString = passUniqueString;
        clone.email = email;
        delete clone['password-2'];
        if (clone.passUniqueString && clone.email && clone.newPass) {
            const res = await confirmResetPassAPI(clone);
            let mensaje = document.getElementById("res-message");
            if (res === "Contraseña actualizada y email validado correctamente") {
                mensaje.innerHTML = "Contraseña actualizada y email validado correctamente. <br></br> Inicie sesión con su nueva contraseña";
                mensaje.style = "color: green;";
                setTimeout(() => {
                    navigate("/login")
                }, 4000);
            } else {
                mensaje.innerHTML = "Ha ocurrido un error. Intentelo de nuevo más tarde o contacte con nosotros en info@lausina.es";
                mensaje.style = "color: red;";
            }
        }
    }

    // useEffect(async () => {
    //     if (passUniqueString?.length > 1) {
    //         await resetPassAPI({ passUniqueString })
    //             .then(res => {
    //                 if (res.includes("Email validado")) {
    //                     setMessage("Email validado correctamente")
    //                     setTimeout(() => {
    //                         if (userDetails.user.validado) navigate('/sala/programacion/' + id + '/' + date + '/comprar-entradas/ok')
    //                         else navigate('/sala/programacion/' + id + '/' + date + '/comprar-entradas/login')
    //                     }, 4000);
    //                 }
    //             });
    //     }
    // }, [passUniqueString]);

    return (<StyledForm>
        <h1>Introduce tu nueva contraseña</h1>
        <Form
            onSubmit={onSubmit}
            validate={values => {
                const errors = {}
                if (!values.newPass) {
                    errors.newPass = 'Requerido'
                } else if (values.newPass.length < 8) {
                    errors.newPass = 'La contraseña debe tener al menos 8 caracteres';
                }
                if (!values['password-2']) {
                    errors['password-2'] = 'Requerido'
                } else if (values.newPass !== values['password-2']) {
                    errors['password-2'] = 'Las contraseñas no coinciden';
                }
                return errors
            }}
            render={({ handleSubmit, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                    <Field name="newPass">
                        {({ input, meta }) => (
                            <div className="input">
                                <label>Contraseña</label>
                                <input {...input} type="password" placeholder="Contraseña" />
                                {meta.error && meta.touched && <span>{meta.error}</span>}
                            </div>
                        )}
                    </Field>
                    <Field name="password-2">
                        {({ input, meta }) => (
                            <div className="input">
                                <label>Confirmar contraseña</label>
                                <input {...input} type="password" placeholder="Confirmar contraseña" />
                                {meta.error && meta.touched && <span>{meta.error}</span>}
                            </div>
                        )}
                    </Field>
                    <div className="buttons-login">
                        <button type="submit" disabled={submitting || invalid}>
                            Continuar
                        </button>
                        <button className="button-reversed" onClick={(e) => { e.preventDefault(); navigate("/login") }}>
                            Volver
                        </button>
                        <br></br>
                    </div>
                    <p id="mensaje-error"></p>
                </form>
            )}
        />
        <span id="res-message"></span>
    </StyledForm>)
}

export default ChangePassword;