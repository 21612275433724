import { useEffect } from 'react';
import Layout from '../../../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfesor, selector } from '../../../../redux/slices/EquipoSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from "draft-js";
import { PageStyled } from '../../../../style';
import { HrStyled } from '../../../../components/Nav';
import { convertirSinopsis } from '../../../../components/Helpers/convertirSinopsis';



function Profesor() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { profesor, loading } = useSelector(selector);

    useEffect(() => {
        dispatch(fetchProfesor(id));
    }, [])

    const handleClickLeft = (e) => {
        navigate(-1);
    }

    if (loading) return <h3>Cargando...</h3>
    else return (
        <PageStyled>
            <div className="head-p">
                <i className="fas fa-chevron-left" onClick={handleClickLeft}></i>
                <p className='titulo-p'>{profesor.nombre}</p>
            </div>
            <HrStyled />
            <img src={profesor.cartel}></img>
            <HrStyled />
            {profesor?.descripcion && <div
                className='sinopsis'
                id='sinopsis'
                dangerouslySetInnerHTML={{
                    __html: convertirSinopsis(profesor?.descripcion)
                }}></div>}
        </PageStyled>
    );
}

export default Profesor;