import { Form, Field } from 'react-final-form'
import { postAPI } from '../../../api';
import { StyledForm } from '../../../style';
import styled from 'styled-components';

const onSubmit = async values => {
    const response = await postAPI('contactos', values)
    document.getElementById("respuesta").innerHTML = response;
}
const required = (value) => (value ? undefined : "Este campo es obligatorio.");

const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return email && !String(email).toLowerCase().match(re) ? "Correo no válido" : undefined;
};

const Error = ({ name }) => (
    <Field
        name={name}
        subscribe={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
            touched && error ? <span style={{ color: 'red' }}>{error}</span> : null
        }
    />
);

// Función auxiliar para componer validaciones
function composeValidators(...validators) {
    return value =>
        validators.reduce((error, validator) => error || validator(value), undefined);
}

const App = () => (
    <StyledForm>
        <StyledContact>
            <h1>Contacta y suscríbete</h1>
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="input">
                            <Field name="nombre" component="input" placeholder="Nombre" validate={required} />
                            <Error name="nombre" />
                        </div>
                        <div className="input">
                            <Field name="email" component="input" placeholder="Email" validate={composeValidators(required, validateEmail)} />
                            <Error name="email" />
                        </div>
                        <div className="input">
                            <Field name="telefono" component="input" placeholder="Teléfono" validate={required} />
                            <Error name="telefono" />
                        </div>
                        <div className="input">
                            <Field name="mensaje" component="input" placeholder="Mensaje" />
                            <Error name="mensaje" />
                        </div>
                        <div className='buttons-login'>
                            <button type="submit" disabled={submitting || invalid}>Contactar y Suscribirse</button>
                        </div>
                        <p id="respuesta"></p>
                    </form>
                )}
            />
            {/* <StyledDataContact>
                <h2>+34 670 580 570</h2>
                <div className='row'>
                    <center><a className='icon-button' href="tel:34670580570" alt=""><i className="fas fa-phone"></i></a></center>
                    <center><a className='icon-button' href="https://wa.me/34670580570" alt=""><i className="fab fa-whatsapp"></i></a></center>
                </div>
                <h2>info@lausina.es</h2>
                <div className='row'>
                    <center><a className='icon-button' href="mailto:info@lausina" alt=""><i className="fas fa-envelope"></i></a></center>
                </div>
            </StyledDataContact> */}
        </StyledContact>
    </StyledForm>
)

export default App;

const StyledDataContact = styled.div`
    display: flex;
    min-width: 70%;
    align-items: center;
    justify-content: space-around;
    align-text: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
    .row {
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    a.icon-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 0.5rem;
        margin-inline: 0.3rem;
        background-color: white;
        color: #f46d2b; /* Color del ícono */
        border: 1px solid #f46d2b;
        border-radius: 0.3rem;
        text-decoration: none; /* Esto removerá el subrayado típico de los enlaces */
        transition: background-color 0.3s, transform 0.3s; /* Suave transición para efectos hover y active */
        font-size: 1rem; /* Ajusta el tamaño según lo que prefieras */
    }
    
    a.icon-button:hover {
        background-color: #e45622; /* Un tono un poco más oscuro cuando el mouse esté encima */
        color: white;
    }
    
    a.icon-button:active {
        transform: scale(0.95); /* Hace que el botón se "presione" un poco cuando es clickeado */
    }
    
    a.icon-button i.fab, a.icon-button i.fas {
        margin-right: 0.5rem; /* Espacio entre el ícono y el texto, si es que tienes texto al lado del ícono */
    }
`

const StyledContact = styled.div`
   form, input {
        margin: 0;
        outline: none;
        box-shadow: none;
        width: 100%;
    }

   input {
        width: 100%;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 2px solid #f46d2b; /* Color de borde naranja */
        border-radius: 5px; /* Esquinas redondeadas */
        background-color: #f9f9f9; /* Un fondo claro pero no completamente blanco */
        color: #333; /* Color de texto oscuro pero no completamente negro */
        font-size: 16px; /* Tamaño de letra un poco más grande */
        transition: border-color 0.3s; /* Efecto suave al cambiar el color de borde */

        /* Cambio de estilo al enfocar el input */
        &:focus {
            border-color: #333; /* Color de borde oscuro al enfocarse */
        }

        /* Estilo para mostrar cuando hay un error (puedes ajustarlo según tus necesidades) */
        &.error {
            border-color: red;
            background-color: #ffe5e5;
        }

        /* Estilo del placeholder */
        &::placeholder {
            color: #aaa; /* Color de placeholder más claro */
        }
    }
`;

