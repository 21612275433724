import { useEffect } from 'react';
import Layout from '../../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTalleres, selector } from '../../../redux/slices/CursosSlice';
import { useNavigate } from 'react-router-dom';
import { CardStyled } from '../../../style';

function Talleres() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Talleres, loading } = useSelector(selector);

    useEffect(() => {
        dispatch(fetchTalleres());
    }, [])

    if (loading) return <h3>Cargando...</h3>
    else return <TableObrasMes />;

    function openEdit(e, id) {
        e.preventDefault();
        navigate(`/escuela/${id}`)
    }

    function Card({ index, obra, color, colorText, colorText2 }) {
        return (
            <CardStyled
                key={index}
                color={color}
                colorText={colorText}
                colorText2={colorText2}
                onClick={(e) => { openEdit(e, obra?._id) }}>
                <div className='image-container'>
                    <img className="image-card" src={obra?.cartel}></img>
                </div>
                <div className="texto-obra">
                    <p className="titulo">{obra?.nombre}</p>

                </div>
            </CardStyled>
        )
    }

    function TableObrasMes() {
        let nextAuxNum = 2;
        let colorText = "white";
        let colorText2 = "black";
        let color = "#f46d2b";
        return Talleres.map((obra, index) => {
            if (nextAuxNum % 2 === 0) {
                colorText = "white";
                colorText2 = "black";
                color = "#f46d2b";
            } else {
                colorText = "black";
                colorText2 = "#f46d2b";
                color = "white";
            }
            nextAuxNum++;
            return <Card key={index} obra={obra} color={color} colorText={colorText} colorText2={colorText2} />
        })
    }
}

export default Talleres;