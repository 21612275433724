import Layout from '../../../components/Layout';
import { HrStyled } from '../../../components/Nav';
import { PageStyled } from '../../../style';

function App() {
    return (
        <PageStyled>
            <p className='head-1'>Alquila nuestras salas</p>
            <HrStyled />
            <img src="https://lausinabucket2.s3.eu-west-1.amazonaws.com/La+Usina+Sala+de+Teatro+en+Madrid+5.jpg"></img>
            <HrStyled />

            <div className='text'>
                <p>Ofrecemos <span className="orange">nuestras salas </span>para eventos privados.</p>
            </div>
            <HrStyled />
            <img src="https://lausinabucket2.s3.eu-west-1.amazonaws.com/La+Usina+Sala+de+Teatro+en+Madrid+6.jpg"></img>
            <HrStyled />
            <div className='text'>
                <p>Ensayos, castings, clases, conferencias, presentaciones, eventos empresariales, rodajes, sesiones de fotos y cualquier otro tipo de <span className="orange">actividad cultural</span>.</p>
            </div>
            <HrStyled />
            <img src="https://lausinabucket2.s3.eu-west-1.amazonaws.com/La+Usina+Sala+de+Teatro+en+Madrid+1.jpg"></img>
            <HrStyled />
            <img src="https://lausinabucket2.s3.eu-west-1.amazonaws.com/La+Usina+Sala+de+Teatro+en+Madrid+2.jpg"></img>
            <HrStyled />
            <img src="https://lausinabucket2.s3.eu-west-1.amazonaws.com/La+Usina+Sala+de+Teatro+en+Madrid+4.jpg"></img>
            <HrStyled />
            <p className='head'>Sala auxiliar</p>
            <HrStyled />
            <img src="https://lausinabucket2.s3.eu-west-1.amazonaws.com/La+Usina+Sala+de+Teatro+en+Madrid+3.jpg"></img>
            <HrStyled />
            <p className='head'>Detalles</p>
            <div className='text'>
                <p>La sala tiene suelo de linóleo, tres telones a diferentes distancias y cuenta con proyector sin shutter, mesa de luces programable en 12 canales, y veinte focos (incluyendo fresnel, pc, par 56 y par 64) distribuidos en cuatro barras y dos en suelo.</p>
            </div>
            <button><a href="mailto:info@lausina.es">Enviar email</a></button>
            <button><a href="tel:34670580570">Llamar ahora</a></button>
        </PageStyled>
    );
}

export default App;