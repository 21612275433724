import { useEffect } from 'react';
import Layout from '../../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchObrasNow, selector } from '../../../redux/slices/ObrasSlice';
import { useNavigate } from 'react-router-dom';
import Head from './Head';
import { CardProgramacionStyled } from '../../../style';

const nombreMes = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

function App() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { listNow, loading } = useSelector(selector);
    const mesActual = new Date().getMonth() + 1;

    useEffect(() => {
        dispatch(fetchObrasNow());
    }, [])

    return (
        <>
            <Head mes={"Calendario"} />
            <TableObrasNow />
        </>
    );

    function openEdit(e, id, date) {
        e.preventDefault();
        navigate(`/sala/programacion/${id}/${date}`)
    }

    function openEntradas(e, id) {
        e.preventDefault();
        // navigate(`/sala/programacion/${id}`)
    }

    function Card({ index, obra, otherDate, color, colorText }) {
        const { fecha, titulo, direccion } = obra;
        const date = new Date(fecha);
        const hour = date.getHours();
        let min = date.getMinutes();
        if (min < 10) min = "0" + min;
        return (
            <CardProgramacionStyled
                key={fecha}
                otherDate={otherDate}
                color={color}
                colorText={colorText}>
                <div className="date">
                    <div className="day">{fecha && date.getDate()}</div>
                    <div className="month">{fecha && nombreMes[date.getMonth()]}</div>
                </div>
                <div className="texto-obra"
                    onClick={(e) => { openEdit(e, obra._id, fecha) }}>
                    <p className='hour-2'>{hour + ":" + min}</p>

                    <div className="obra-datos">
                        <p className="link">{titulo}&nbsp;</p>
                        {/* <p className="link">{direccion.map((dire, index) => {
                            if (index < 1) return '· ' + dire
                            return ", " + dire
                        })}</p> */}
                    </div>
                    {/* {obra.disponibles > 0 ?
                        <pre className="entradas">entradas</pre> :
                        <pre>agotadas</pre>} */}

                    <p className='hour'>{hour + ":" + min}</p>
                </div>
                <div className="date-2">
                    <div className="day">{fecha && date.getDate()}</div>
                    <div className="month">{fecha && nombreMes[date.getMonth()]}</div>
                </div>
            </CardProgramacionStyled>
        )
    }

    function TableObrasNow() {
        if (loading || listNow.length < 1) return <h1>Cargando...</h1>
        let lastDay = 0;
        let nextAux = true;
        let nextAuxNum = 2;
        let otherDate = false;
        let colorText = "white";
        let color = "#f46d2b";
        return listNow?.map((obra, index) => {
            if (obra.publicada) {
                const { fecha } = obra;
                const date = new Date(fecha)
                const fechaUnique = date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate();
                if (fechaUnique > lastDay) {
                    otherDate = true
                    lastDay = fechaUnique;
                    nextAux = !nextAux;
                    nextAuxNum = 2;
                } else {
                    otherDate = false;
                };
                if (nextAux) {
                    if (nextAuxNum % 2 === 0) {
                        colorText = "white";
                        color = "black";
                    } else {
                        color = "white";
                        colorText = "black";
                    }
                } else {
                    if (nextAuxNum % 2 === 0) {
                        color = "#f46d2b";
                        colorText = "white";
                    } else {
                        colorText = "#f46d2b";
                        color = "white";
                    }
                }
                nextAuxNum++;
                return <Card key={index} obra={obra} color={color} colorText={colorText} otherDate={otherDate} />
            }
        })
    }
}

export default App;
