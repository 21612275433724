import { loginAPI, signupAPI } from "../api";

export async function loginUser(dispatch, loginPayload) {

  try {
    dispatch({ type: "REQUEST_LOGIN" });
    let data = await loginAPI(loginPayload)
    if (data?.token) {
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      localStorage.setItem("currentUser", JSON.stringify(data));
      return data;
    }
    return "Usuario/Contraseña incorrectos";
  } catch (error) {
    console.log(error);
  }
}

export async function signUpUser(dispatch, signUpPayload) {
  try {
    let data = await signupAPI(signUpPayload)
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function logout(dispatch) {
  localStorage.removeItem("currentUser");
  dispatch({ type: "LOGOUT" });
}

export function setError(dispatch, error) {
  localStorage.removeItem("currentUser");
  dispatch({ type: "SET_ERROR", payload: error});
}

