import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { loginUser, useAuthDispatch } from "../../context";
import { Form, Field } from 'react-final-form'
import { StyledForm } from "../../style";
import { resetPassAPI } from "../../api";


function Login() {

  // TODO: Añadir error al iniciar sesion mensaje 
  const [showChangePass, setShowChangePass] = useState(false)
  const dispatch = useAuthDispatch();
  let navigate = useNavigate();
  const { id, date } = useParams();

  const onSubmit = async values => {
    try {
      if (showChangePass) {
        const res = await resetPassAPI({ email: values.email });
        console.log(res)
        let mensaje = document.getElementById("mensaje-error");
        mensaje.innerHTML = res;
        mensaje.style = "color: green;";
        setShowChangePass(false)
      } else {
        let data = await loginUser(dispatch, { email: values.email, password: values.password });
        if (data?.user?.validado) {
          if(id && date) {
            const route = window.location.pathname.split('/').slice(0, -1).join('/') + '/ok';
            navigate(route);
          } else {
            navigate("/")
          }
        } else {
          let mensaje = document.getElementById("mensaje-error");
          mensaje.innerHTML = "Usuario/Contraseña incorrecto<br>Recuerde validar su email";
          mensaje.style = "color: red;";
        }
      }

    } catch (e) {
      let mensaje = document.getElementById("mensaje-error");
      mensaje.innerHTML = e;
      mensaje.style = "color: red;";
      console.log(e)
    }

  }

  return (
    <StyledForm>
      <h1>Inicia sesión para comprar tus entradas</h1>
      <Form
        onSubmit={onSubmit}
        validate={values => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Requerido'
          }
          if (!values.password && !showChangePass) {
            errors.password = 'Requerido'
          }
          return errors
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Field name="email">
              {({ input, meta }) => (
                <div className="input">
                  <label>Email</label>
                  <input {...input} type="text" placeholder="Email" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>
            {!showChangePass && <Field name="password">
              {({ input, meta }) => (
                <div className="input">
                  <label>Contraseña</label>
                  <input {...input} type="password" placeholder="Password" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>}
            <button className="button-reversed" onClick={() => setShowChangePass(!showChangePass)}>{showChangePass ? "Iniciar sesión" : "He olvidado mi contraseña"}</button>
            <div className="buttons-login">
              <button type="submit" disabled={submitting}>
                Continuar
              </button>
              <br></br>
              <button className="button-reversed" onClick={(e) => { e.preventDefault(); navigate('/sala/programacion/' + id + '/' + date + '/comprar-entradas/signup') }}>Crear nueva cuenta</button>
            </div>
            <p id="mensaje-error"></p>
          </form>
        )}
      />
    </StyledForm>
  );
}

export default Login;

export const LogginStyled = styled.div`

`