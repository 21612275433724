import axios from "axios";

const apiUrl = process.env.REACT_APP_LA_USINA_API_URL;

const api = axios.create({
    baseURL: apiUrl,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Usa un interceptor para actualizar el encabezado Authorization antes de cada solicitud.
api.interceptors.request.use((config) => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"))
    if (currentUser?.token) {
        config.headers.Authorization = currentUser.token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});


export default api;