import { PageStyled } from '../../style';

function Transparencia() {
    return (
        <PageStyled style={{ paddingInline: 100 }}>
            <h3>Criterio de Transparencia: </h3>
            <p>Al respecto de la ley 19/2013, de 9 de diciembre, de transparencia, acceso a la información pública y buen gobierno, Teatro La Usina SL informa que ha recibido la Ayuda a Salas de Pequeño Formato de Actividad Escénica del Área de Gobierno de Cultura y Deportes del Ayuntamiento de Madrid 2022/2023: 92.972,68€.</p>
        </PageStyled>

    );
}


export default Transparencia;