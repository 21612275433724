import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchObrasMes, selector } from '../../../../redux/slices/ObrasSlice';
import { useNavigate, useParams } from 'react-router-dom';
import Head from '../Head';
import { CardStyled } from '../../../../style';

const nombreMes = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

function App() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { anio, mes } = useParams();
    const { listMes, loading } = useSelector(selector);

    useEffect(() => {
        dispatch(fetchObrasMes(`${anio}/${mes}`));
    }, [mes])

    if (loading) return <h3>Cargando...</h3>;
    else return <>
        <Head mes={mes} anio={anio} />
        <TableObrasMes />
    </>;

    function openEdit(e, id, fechas) {
        e.preventDefault();
        navigate(`/sala/programacion/${id}/${fechas[0]}`)
    }

    function Card({ index, obra, otherDate, color, colorText, colorText2 }) {
        const { titulo, compania } = obra;
        return (
            <CardStyled
                key={index}
                color={color}
                colorText={colorText}
                colorText2={colorText2}
                onClick={(e) => { openEdit(e, obra._id, obra.fechas) }}>
                <div className='image-container'>
                    <img className="image-card" src={obra.cartel}></img>
                </div>
                <div className="texto-obra">
                    <p className="titulo">{titulo}<br></br><span>{compania}</span></p>
                </div>
            </CardStyled>
        )
    }

    function TableObrasMes() {

        let nextAuxNum = 2;
        let colorText = "white";
        let colorText2 = "black";
        let color = "#f46d2b";
        return listMes.map((obra, index) => {
            if (obra.publicada) {
                if (nextAuxNum % 2 === 0) {
                    colorText = "white";
                    colorText2 = "black";
                    color = "#f46d2b";
                } else {
                    colorText = "black";
                    colorText2 = "#f46d2b";
                    color = "white";
                }
                nextAuxNum++;
                return <Card key={index} obra={obra} color={color} colorText={colorText} colorText2={colorText2} />
            }
        })
    }
}

export default App;
