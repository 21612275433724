import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {getAPI} from "../../api";

export const fetchEquipo = createAsyncThunk(
    "Cursos/fetchEquipo",
    async () => {
       return await getAPI('equipo')
          .then((data) => {return data});
    }
);

export const fetchProfesor = createAsyncThunk(
    "Cursos/fetchProfesor",
    async (id, thunkAPI) => {
       return await getAPI(`equipo/${id}`)
          .then((data) => {return data});
    }
);


export const EquipoSlice = createSlice({
  name: "equipo",
  initialState: {
    Equipo: [],
    loading: false,
    profesor: {},
  },
  reducers: {},
  extraReducers: {

    [fetchEquipo.pending]: (state) => {
      state.loading = true;
    },
    [fetchEquipo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.Equipo = payload;
    },
    [fetchEquipo.rejected]: (state) => {
      state.loading = true;
    },

    [fetchProfesor.pending]: (state) => {
      state.loading = true;
    },
    [fetchProfesor.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.profesor = payload;
    },
    [fetchProfesor.rejected]: (state) => {
      state.loading = true;
    },

  },
});

export const selector = (state) => state.equipo;

export default EquipoSlice.reducer;
