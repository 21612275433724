import { useNavigate } from "react-router-dom";
import { CardProgramacionStyled } from "../../../../style";

function Head({ anio, mes }) {

    const navigate = useNavigate();
    let fechaTexto;


    if (!mes.includes("Calendario")) {
        fechaTexto = new Date();
        fechaTexto.setFullYear(anio);
        fechaTexto.setMonth(mes - 1);
        fechaTexto = fechaTexto.toLocaleDateString("es-ES", { year: 'numeric', month: 'long' })
    } else {
        fechaTexto = "Calendario"
    }

    const mesActual = new Date().getMonth() + 1;
    const anioActual = new Date().getFullYear();

    const handleClickLeft = (e) => {
        e.preventDefault();
        if (mes.includes("Calendario")) {
            mes = new Date().getMonth() + 1;
            anio = new Date().getFullYear();
        } else {
            if (mes < 2) {
                mes = 12;
                anio--;
            } else {
                mes--;
            }
        }
        navigate(`/sala/programacion/mes/${anio}/${mes}`)
    }
    const handleClickRight = (e) => {
        e.preventDefault();
        if (!mes.includes("Calendario")) {
            if (mes > 11) {
                mes = 1;
                anio++;
            } else {
                mes++;
            }
            if (mes > mesActual && anio >= anioActual) {
                navigate(`/sala/programacion`)
            } else {
                navigate(`/sala/programacion/mes/${anio}/${mes}/`)
            }
        }
    }
    return (
        <CardProgramacionStyled>
            <div className="head-container">
                <div className="arrow" onClick={handleClickLeft}>
                    <i className="fas fa-chevron-left"></i>
                    {fechaTexto === "Calendario" && window.innerWidth > 1118 && <p className="p-arrow">ver histórico</p>}
                </div>
                <div id="head">{fechaTexto}</div>
                <div className="arrow" onClick={handleClickRight}>
                    {mes != "Calendario" && <i className="fas fa-chevron-right"></i>}
                </div>

            </div>
        </CardProgramacionStyled>
    );
}

export default Head;