import { useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";

function GraciasPorSuCompra({ queryString, detallesCompra }) {

    // const [detallesCompra] = useState(JSON.parse(localStorage.getItem("detallesCompra")));

    const navigate = useNavigate();

    const mySubString = queryString.substring(
        queryString.indexOf("=") + 1,
        queryString.indexOf("&")
    );

    const dateValue = Number(detallesCompra?.fechaObra)
    const date = new Date(dateValue).toLocaleDateString("es-ES", {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    })

    return (
        <>
            <h1>Gracias por su compra</h1>
            <p>El siguiente QR corresponde a su entrada</p>
            <p>(También se lo hemos enviado a su email)</p>
            <QRCode value={mySubString} />
            <p>{detallesCompra.cantidad + " entrada(s) para " + detallesCompra.titulo}</p>
            <p>{date}</p>
            <p>Las entradas son sin numerar, se les asignará butaca a su llegado al teatro.</p>
            <p>Para personas con discapacidad, se aconseja ponerse en contacto con la sala en el 670 580 570 o info@lausina.es y se les reservarán butacas específicas.</p>
            <p>Gracias. </p>
            <button onClick={(e) => { e.preventDefault(); navigate('/sala/programacion/') }}>Seguir navegando</button>
        </>
    );
}

export default GraciasPorSuCompra;