import obrasReducer from "./slices/ObrasSlice";
import cursosReducer from "./slices/CursosSlice";
import equipoReducer from "./slices/EquipoSlice";

import { combineReducers } from "@reduxjs/toolkit";

export default combineReducers({
  obras: obrasReducer,
  cursos: cursosReducer,
  equipo: equipoReducer,
});
