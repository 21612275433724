import { useNavigate, useParams } from "react-router-dom";
import { getAPI } from "../../api";
import { useEffect, useState } from "react";
import { useAuthState } from "../../context";

const ValidateEmail = () => {

    const { id, date, uniqueString } = useParams();
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const [message, setMessage] = useState(undefined)

    useEffect(async () => {
        if (uniqueString?.length > 1) {
            await getAPI("verify/" + uniqueString)
                .then(res => {
                    if (res.includes("Email validado")) {
                        setMessage("Email validado correctamente")
                        setTimeout(() => {
                            if (userDetails.user.validado) navigate('/sala/programacion/' + id + '/' + date + '/comprar-entradas/ok')
                            else navigate('/sala/programacion/' + id + '/' + date + '/comprar-entradas/login')
                        }, 4000);
                    }
                });
        }
    }, [uniqueString]);
    return <center><span>{message}</span></center>
}

export default ValidateEmail;