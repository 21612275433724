import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { GlobalStyle } from '../../components/Layout';
import { Logo } from '../../components/Nav';
import { mobileWidth } from '../../style';

function App() {
    return (
        <>
            <GlobalStyle />
            <Video />
            <HomeStyled>
                <TopStyled>
                    <Logo colorWhite={true} />
                </TopStyled>
                <BottomStyled>
                    <HomeLinkStyled to="/sala">Sala</HomeLinkStyled>
                    <HomeLinkStyled to="/escuela">Escuela</HomeLinkStyled>
                </BottomStyled>
            </HomeStyled>
        </>
    );
}

function Video() {
    return (
        <VideoStyled loop autoPlay muted id="video">
            <source
                src="https://lausinabucket2.s3.eu-west-1.amazonaws.com/Sala+de+Teatro+La+Usina.mp4"
                type="video/mp4"
            />
            Your browser does not support the video tag.
        </VideoStyled>
    )
}

export default App;

export const ResetStyles = styled.div`
    all: initial;
    * {
        all: initial;
    }
`;

const BottomStyled = styled.div`
    display: flex;  // Agregado para que los hijos estén en fila
    width: 100%;    // Ocupa todo el ancho disponible
    justify-content: space-around;  // Separar los links equitativamente
    height: 80%;
    @media (max-width: ${mobileWidth}) {
        align-items: center;    
        flex-direction: column;
    }
`;

const TopStyled = styled.div`
    display: flex;  // Agregado para que los hijos estén en fila
    width: 100%;    // Ocupa todo el ancho disponible
    height: 10%;
    margin-top: 4%;
    margin-bottom: 4%;
    justify-content: space-around;  // Separar los links equitativamente
`;

const HomeStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    font-family: 'Neuropol';
    @media (max-width: ${mobileWidth}) {
        flex-direction: column;
    }
`
const HomeLinkStyled = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5vw solid white;
    border-radius: 10px;
    width: 45%;
    height: 90%;
    font-size: 6vw;
    color: white;
    text-decoration: none;
    @media (max-width: ${mobileWidth}) {
        width: 90%;
        height: 45%;
    }
    :hover{
        cursor:pointer;
        color: orange;
        border-color: orange;
    }
`

const VideoStyled = styled.video`
    display: block;
    position: fixed;
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: 100%;
`