import React, { useEffect, useState } from "react";
import { fetchObra, selector } from "../../../redux/slices/ObrasSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logout, useAuthDispatch } from "../../../context";
import { StyledForm } from "../../../style";


function SeleccionarFechas({ setDetallesCompra, detallesCompra, titulo }) {
    const dispatch = useDispatch();
    const dispatchAuth = useAuthDispatch();
    const navigate = useNavigate();
    const { id, date } = useParams();
    const [localNotas, setLocalNotas] = useState(detallesCompra.notasEspeciales);

    const { obra } = useSelector(selector);

    const { fechasClean } = obra;

    useEffect(() => {
        if (!obra.titulo) dispatch(fetchObra(id));
    }, [])

    const cantidades = []

    const disponibles = entradasDisponibles();
    for (let i = 1; i <= disponibles && i < 10; i++) {
        cantidades.push(i);
    }

    const handleChangeCantidad = async values => {
        setDetallesCompra({
            ...detallesCompra,
            cantidad: values.target.value
        })
    }

    const handleChangeFechas = async values => {
        const arr = window.location.pathname.split('/');
        arr[arr.length - 3] = values.target.value;
        const route = arr.join("/");
        setDetallesCompra({
            ...detallesCompra,
            fechaObra: values.target.value
        })
        navigate(route);
    }

    function entradasDisponibles() {
        let fechaAux = obra?.fechasClean?.filter(f => f.fecha == detallesCompra.fechaObra)[0];
        return parseInt(fechaAux?.disponibles)
    }

    const handleSubmit = async values => {
        setDetallesCompra({
            ...detallesCompra,
            notasEspeciales: localNotas || ""
        });
        setTimeout(() => {
            const route = window.location.pathname.split('/').slice(0, -1).join('/') + '/pago';
            navigate(route)
        }, 500);
    }

    async function logOut() {
        await logout(dispatchAuth);
    }
    return (
        <StyledForm>
            <form onSubmit={handleSubmit}>
                <h1>{titulo}</h1>
                <div>
                    <h3>Comprar entradas para:</h3>
                    <h1>{detallesCompra?.titulo}</h1>
                    <p>Cantidad:</p>
                    <label>{detallesCompra?.cantidad}</label>
                    <p>Enviar a:</p>
                    <label>{detallesCompra?.email}</label>
                    <p>Teléfono de contacto:</p>
                    <label>{detallesCompra?.phone}</label>
                    <p>Notas e indicaciones especiales</p>
                    <textarea
                        key="text-area-notas"
                        value={localNotas}
                        onChange={(e)=>setLocalNotas(e.target.value)}
                        rows="5"
                        cols="50"
                        placeholder="Escribe aquí..."
                    />
                    <label>
                        Fecha:&nbsp;&nbsp;
                        <select onChange={handleChangeFechas} value={detallesCompra.fechaObra} id="fechas-select">
                            {fechasClean?.map((item, index) => {
                                return (<option key={index} value={item.fecha}>{
                                    new Date(item.fecha).toLocaleDateString("es-ES", {
                                        weekday: 'long',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    })}</option>);
                            })}
                        </select>
                    </label>
                </div>
                <div>
                    {disponibles > 0 ?
                        <label>
                            Cantidad:&nbsp;&nbsp;
                            <select onChange={handleChangeCantidad} value={detallesCompra.cantidad}>
                                {cantidades?.map(item => {
                                    return (<option key={item} value={item}>{item}</option>);
                                })}
                            </select>
                        </label> :
                        <pre>Agotadas</pre>}
                </div>
                <div>
                    {disponibles > 0 && <button type='submit'>Pagar</button>}
                    <button onClick={(e) => { e.preventDefault(); navigate("/sala/programacion") }}>Volver</button>
                </div>
                <a id="cerrar" onClick={logOut}>Cerrar sesión</a>

            </form>
        </StyledForm>
    );
}

export default SeleccionarFechas;