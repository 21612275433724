import { useEffect } from 'react';
import Layout from '../../../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { convertToHTML } from 'draft-convert';
import { convertFromRaw } from "draft-js";
import { ObraStyled } from '../../../../style';
import { fetchObra, selector } from '../../../../redux/slices/ObrasSlice';
import entradiumLogo from "./img/entradium.png";
import atrapaloLogo from "./img/atrapalo.png";
import { convertirSinopsis } from '../../../../components/Helpers/convertirSinopsis';

const nodeEnv = process.env.REACT_APP_NODE_ENV;

function App() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, date } = useParams();

    const { obra, loading } = useSelector(selector);

    useEffect(() => {
        dispatch(fetchObra(id));
    }, [id])

    useEffect(() => {
        if (!date && obra?.fechasClean?.length > 0) {
            const dateObj = obra.fechasClean.find(fecha => fecha.disponibles > 0);

            if (dateObj) {
                const date2 = dateObj.fecha;
                const route = window.location.pathname + date2;
                navigate(route);
            }
        }

    }, [obra?.fechasClean])




    const handleClickLeft = (e) => {
        navigate(-1);
    }

    let sinopsis = convertirSinopsis(obra.sinopsis);
    // if (obra?.esNueva) {
    //     sinopsis = convertirSinopsis(obra.sinopsis)
    // } else {
    //     sinopsis = obra.sinopsis
    // }

    const onComprarEntradas = () => {
        navigate('comprar-entradas/login')
        // <ComprarEntradas userDetails={userDetails} obra={obra} />
    }

    if (loading) return <h3>Cargando...</h3>;
    else return (
        <ObraStyled>
            <div className="head">
                <i className="fas fa-chevron-left" onClick={handleClickLeft}></i>
                <p className='titulo'>{obra.titulo}</p>
            </div>
            <img src={obra.cartel}></img>
            {sinopsis && <div className='text'>
                <p className='title'>Sinopsis</p>
                <div
                    className='sinopsis'
                    dangerouslySetInnerHTML={{
                        __html: sinopsis
                    }}></div>
            </div>}
            {obra.autores?.length > 0 && <div className='text'>
                <p className='title'>{obra.autores?.lenght > 1 ? "Autores" : "Autor"}</p>
                <p>{obra.autores?.map((autor, idx) => {
                    return <p key={idx}>{autor}</p>
                })}</p>
            </div>}
            {obra.direccion?.length > 0 &&
                <div className='text'>
                    <p className='title'>{obra.direccion?.lenght > 1 ? "Directores" : "Director"}</p>
                    <p>{obra.direccion?.map((autor, idx) => {
                        return <p key={idx}>{autor}</p>
                    })}</p>
                </div>}
            {obra.compania && <div className='text'>
                <p className='title'>Compañia</p>
                <p>{obra.compania}</p>
            </div>}
            {obra.actores?.length > 0 && <div className='text'>
                <p className='title'>Elenco</p>
                {obra.actores?.map((actor, idx) => {
                    return <p key={idx}>{actor}</p>
                })}
            </div>}
            {obra.fechas?.length > 0 && obra.fechas.some(obj => obj.hasOwnProperty('fecha'))
                ? <div className='text'>
                    <p className='title'>Fechas</p>
                    {obra.fechas?.map((fecha, idx) => {
                        if (fecha?.fecha) {
                            const dateClean = new Date(fecha?.fecha).toLocaleDateString("es-ES", {
                                weekday: 'long',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            })
                            if (dateClean === "Invalid Date") return <p key={idx}></p>;
                            else return <p key={idx}>{dateClean}</p>

                        }
                    })}
                </div>
                : obra?.funciones?.texto && <div className='text'>
                    <p className='title'>Fechas</p>
                    <p key="ki">{obra.funciones.texto}</p>
                </div>}


            {obra?.estaActiva && (nodeEnv === "Dev" || nodeEnv === "Stage") && <button onClick={onComprarEntradas}>Comprar entradas</button>}

            <div className='text'>
                <br></br>
                <p className='title'>Otros portales de compra</p>
            </div>
            <img onClick={(e) => { e.preventDefault(); window.open("https://www.atrapalo.com/entradas/la-usina_l2089/", '_blank') }}
                style={{ border: '2px solid #f46d2b', cursor: 'pointer', marginBottom: 10, padding: 20, borderRadius: 15 }}
                src={atrapaloLogo}></img>
            <img onClick={(e) => { e.preventDefault(); window.open("https://entradium.com/es/organizers/teatro-la-usina", '_blank') }}
                style={{ border: '2px solid #f46d2b', cursor: 'pointer', marginBottom: 10, padding: 20, borderRadius: 15 }}
                src={entradiumLogo}></img>



        </ObraStyled>
    );
}

export default App;