import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import icon from "./only-icon-2.png";
import { useState } from "react";
import { mobileWidth } from "../../style";


function App() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const path = window.location.pathname;
    return (
        <NavStyled>
            <Logo />
            <HamburgerNav onClick={() => setIsMenuOpen(!isMenuOpen)} isMenuOpen={isMenuOpen}>
                {/* Aquí puedes añadir un ícono de hamburguesa o simplemente usar texto */}
                <div className="burger">
                    {isMenuOpen
                        ? <button>
                            <i className="fas fa-times"></i>
                        </button>
                        : <button>
                            <i className="fas fa-bars"></i>
                        </button>}
                </div>
                {isMenuOpen &&
                    <>
                        <LinkStyled id="top14" style={{ marginTop: 30 }} to="/sala" path={path.includes("sala")}>Sala</LinkStyled>
                        <HrStyled></HrStyled>
                        <LinkStyled to="/sala/alquiler" path={path.includes("alquiler")}>Alquiler</LinkStyled>
                        <LinkStyled to="/sala/programacion" path={path.includes("programacion")}>Programacion</LinkStyled>
                        <LinkStyled id="top14" style={{ marginTop: 20 }} to="/escuela" path={path.includes("escuela")}>Escuela</LinkStyled>
                        <HrStyled></HrStyled>
                        <LinkStyled to="/escuela/talleres" path={path.includes("talleres")}>Talleres</LinkStyled>
                        <LinkStyled to="/escuela/cursos" path={path.includes("cursos")}>Cursos</LinkStyled>
                        <LinkStyled to="/escuela/equipo" path={path.includes("equipo")}>Equipo</LinkStyled>
                    </>}
            </HamburgerNav>
            <Right>
                <LinksCointainer>
                    <LinkStyled id="top14" to="/sala" path={path.includes("sala")}>Sala </LinkStyled>
                    |
                    <LinkStyled id="top14" to="/escuela" path={path.includes("escuela")}>Escuela</LinkStyled>
                </LinksCointainer>
                <HrStyled />
                {path.includes("sala") ? <SalaNav path={path} /> : <EscuelaNav path={path} />}
            </Right>
        </NavStyled>
    );
}

function SalaNav({ path }) {
    return (
        <LinksCointainer>
            <LinkStyled to="/sala/alquiler" path={path.includes("alquiler")}>Alquiler</LinkStyled>
            |
            <LinkStyled to="/sala/programacion" path={path.includes("programacion")}>Programacion</LinkStyled>
        </LinksCointainer>
    )
}

function EscuelaNav({ path }) {
    return (
        <LinksCointainer>
            <LinkStyled to="/escuela/talleres" path={path.includes("talleres")}>Talleres</LinkStyled>
            |
            <LinkStyled to="/escuela/cursos" path={path.includes("cursos")}>Cursos</LinkStyled>
            |
            <LinkStyled to="/escuela/equipo" path={path.includes("equipo")}>Equipo</LinkStyled>
        </LinksCointainer>
    )
}

export function Logo({ colorWhite }) {
    const navigate = useNavigate();
    return (
        <LogoStyled onClick={() => navigate("/")}>
            <LogoImage />
            <LogoRight>
                <LogoTitle>LA USINA</LogoTitle>
                <LogoQuote colorWhite={colorWhite}>ESPACIO DE FORMACIÓN, CREACIÓN E INVESTIGACIÓN TEATRAL</LogoQuote>
            </LogoRight>
        </LogoStyled>
    )
}

export default App;

const HamburgerNav = styled.div`
    display: none;
    @media (max-width: ${mobileWidth}) {
        display: flex;
        padding: 20px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: column;
        background-color: white;
        position: fixed; /* Fijar el componente */
        top: 0; /* Alineado a la parte superior */
        right: 0; /* Alineado a la derecha */
        margin-top:25px;
        font-size: 1.5rem;
        
        ${p => p.isMenuOpen && `
            width: auto; /* Changed from 100% to auto */
            position: fixed; /* Posición fija para el modal */
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
            max-width: 90vw; /* Ancho completo de la ventana */
            max-height: 90vw; /* Altura completa de la ventana */
            z-index: 1000; /* Asegurarse de que se muestre por encima de otros elementos */
            display: flex; /* Sobreescribe cualquier otro estilo de display */
        `}
          
        button {
            color: #f46d2b;
            font-size: 2.5rem;
            background-color: white;
            border: none;
            /* Removed max-height to allow content to size naturally */
            &:hover {
                cursor: pointer;
            }
        }
    }
`;


const LinkStyled = styled(NavLink)`
    font-family: 'Neuropol';
    color: ${p => p.path ? "#f46d2b" : "black"};
    letter-spacing: 0.2rem;
    margin-inline: 1rem;
    text-decoration: none;
    
    :hover{
        color: #f46d2b;
    }
    :active {
        color: #f46d2b;
    }

`
const LinksCointainer = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    font-family: 'Roboto', sans-serif;
`

const NavStyled = styled.div`

    flex-grow: 1;
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    font-size: 2rem;
    font-family: 'Neuropol';
    height: 8rem;
    margin-bottom: 5rem;
    width: 100%;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0; /* distance from the top */
    background-color: white;
    z-index: 999999;

    @media (max-width: ${mobileWidth}) {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 8rem;
        font-size: 1.2rem;
    }

    #top14 {
        font-size: 2.5rem;
        @media (max-width: ${mobileWidth}) {
            font-size: 2rem;
        }
    }
`

const LogoStyled = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    white-space: nowrap;
    height: 100%;
    margin-inline: 1rem;
    :hover{
        cursor:pointer;
    }
    @media (max-width: ${mobileWidth}) {
        margin-inline: 0;
        justify-content: flex-start;
    }
`
const LogoImage = styled.div`
    display:flex;
    flex-grow: 1;
    background-image: url(${icon});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 7rem;
    height: 100%;
    @media (max-width: ${mobileWidth}) {
        width: 4rem;
        height: 60%;
        
    }
`

const LogoRight = styled.div`
    flex-grow: 1;
`

const LogoTitle = styled.div`
    @media (max-width: ${mobileWidth}) {
        font-size: 2rem;
    }
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 0.3rem;
    color: #f46d2b;
`

const LogoQuote = styled.div`
    font-family: 'Roboto', sans-serif;
    font-size: 1.05rem;
    color: ${p => p.colorWhite ? "white" : "black"};
    font-weight: bold;
    letter-spacing: -0.06rem;
    @media (max-width: ${mobileWidth}) {
        font-size: 0.6rem;
    }
`

const Right = styled.div`
    display:flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    @media (max-width: ${mobileWidth}) {
        display: none;
    }
`

export const HrStyled = styled.hr`
    border: solid #f46d2b;
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box; 
    overflow: hidden;
`